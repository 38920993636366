<template>
  <div class="team-container">
    <video-background imageURL="/images/bg-team.jpg">
      <h1>
        Collectively driving the commitment, quality of work and service that
        our clients require.
      </h1>
    </video-background>
    <span data-aos="fade" data-aos-duration="10" style="display: none">
      no show
    </span>
    <div class="title-container">
      <h2>Our Team</h2>
      <p>
        Our group of professional adjusters have been hand picked to ensure that
        all assignments being handled by our organization meet the very
        stringent professional guidelines expected in our industry.
        <br />
        <br />
        When you send out a P.C.A. Claims Adjuster, you can rest assured that
        our investigation will be thorough and that no corners are cut. Read
        more about them below.
      </p>
    </div>
    <div class="spinner" v-if="!$store.getters.employeesLoaded">
      <easy-spinner size="80" color="#F3D4AE" />
    </div>
    <div class="teams" v-else>
      <div class="office" v-for="(office, i) in offices" :key="`Office ${i}`">
        <h2>{{ office }} Office</h2>
        <hr />
        <div class="employees-container">
          <div class="leaders-container" v-if="(filteredEmployees(office, true).length > 0)">
            <div
              v-for="(employee, j) in filteredEmployees(office, true)"
              data-aos-offset="-10"
              data-aos="fade-up"
              :data-aos-delay="getDelayInMS(j)"
              class="employee"
              :key="employee.Name"
            >
              <router-link :to="`/team/${employee.Slug}`">
                <figure class="hover-img">
                  <img
                    class="profile-image"
                    :src="`${url}${employee.DisplayPhotoCircle.url}`"
                    :alt="employee.Name"
                  />
                </figure>
              </router-link>
              <h3>
                {{ employee.Name }}
              </h3>
              <p>
                {{ employee.Title }}
              </p>
            </div>
          </div>
          <div class="employees">
            <div
              v-for="(employee, j) in filteredEmployees(office)"
              data-aos-offset="-10"
              data-aos="fade-up"
              :data-aos-delay="getDelayInMS(j)"
              class="employee"
              :key="employee.Name"
            >
              <router-link :to="`/team/${employee.Slug}`">
                <figure class="hover-img">
                  <img
                    class="profile-image"
                    :src="`${url}${employee.DisplayPhotoCircle.url}`"
                    :alt="employee.Name"
                  />
                </figure>
              </router-link>
              <h3>
                {{ employee.Name }}
              </h3>
              <p>
                {{ employee.Title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBackground from "../components/VideoBackground.vue";

export default {
  name: "Team",
  components: {
    VideoBackground,
  },
  data() {
    return {
      url: "https://strapi.uptowncreative.io",
      offices: ["Ottawa", "Kingston", "Belleville"],
    };
  },
  computed: {
    employees() {
      return this.$store.getters.employees;
    },
  },
  methods: {
    filteredEmployees(office, leaders) {
      if(leaders){
        return this.employees
        .filter((employee) => {
          return employee.Office === office && (employee.Slug === 'david-cernak' || employee.Slug === 'alana-mcmurtry');
        })
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
      }
      return this.employees
        .filter((employee) => {
          return employee.Office === office && (employee.Slug !== 'david-cernak' && employee.Slug !== 'alana-mcmurtry');
        })
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    getDelayInMS(position) {
      if (window.innerWidth < 576) {
        return 400;
      }
      if (window.innerWidth < 1000){
        if (position % 2 === 1) {
          return 800;
        }
      }
      else {
        if (position % 3 === 1) {
          return 800;
        } else if (position % 3 === 2) {
          return 1200;
        }
      }
      return 400;
    },
  }
};
</script>

<style scoped lang="scss">
.office {
  h2 {
    text-align: center;
  }
  hr {
    width: 80%;
    margin: 0 auto;
  }
}

.employees-container {
  display: block;
  margin: 60px 5%;
}

.leaders-container, .employees {
  margin: 60px 0;
  gap: 50px 0;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.employee {
  padding: 0;
  text-align: center;
  width: 33.33%;
  margin: 0;
  min-width: 250px;
  .hover-img {
    min-width: 242px;
    width: 60%;
    max-width: 300px;
    border: 4px solid #0267ff;
    border-radius: 60%;
    background: #e6edf9;
    margin: 0 auto;
    img {
      display: block;
      width: 100%;
      transition: 0.6s;
      border-radius: 100%;
      filter: grayscale(100%);
      &:hover {
        filter: none;
      }
    }
  }
  h3 {
    margin: 32px 0 8px;
  }
  p {
    margin: 4px 0;
  }
}

@media screen and (max-width: 980px) {
  .employee {
    width: 50%;
  }
}

@media screen and (max-width: $mobile) {
  .employee {
    width: 100%;
  }
}
</style>

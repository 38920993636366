<template>
  <div class="contact">
    <span data-aos="fade" data-aos-duration="10" style="display: none">
      no show
    </span>
    <div class="title-container">
      <h1>Contact us</h1>
      <p>
        With our Head Office in the Canada's Capital, we are complimented with
        two additional fully staffed branch offices in the cities of Kingston &
        Belleville.
      </p>
    </div>
    <div class="offices">
      <div v-for="(item, i) in offices" class="office" v-bind:key="i">
        <h2>{{ item.name }} Office</h2>
        <img :src="item.image" :alt="`${item.name} Office`" />
        <p class="head-office" v-if="item.headOffice">
          <b>Head Office</b>
        </p>
        <p>{{ item.addressLine1 }}</p>
        <p>{{ item.addressLine2 }}</p>
        <p>{{ item.addressLine3 }}</p>
        <br />
        <br />
        <p><b>Telephone:</b> {{ item.telephone }}</p>
        <p><b>Fax:</b> {{ item.fax }}</p>
        <p><b>Toll Free Phone:</b> {{ item.tollFree }}</p>
        <p>
          <b>Email:</b> <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      offices: [
        {
          name: "Ottawa",
          image: "/images/office/ottawa.png",
          headOffice: true,
          addressLine1: "The Chambers of Queensview",
          addressLine2: "2725 Queensview Drive, Suite 100",
          addressLine3: "Ottawa, Ontario K2B 0A1",
          telephone: "(613) 726-9556",
          fax: "(613) 726-0000",
          tollFree: "1-800-722-9556",
          email: "claims@pca-adj.com",
        },
        {
          name: "Kingston",
          image: "/images/office/kingston.png",
          headOffice: false,
          addressLine1: "234 Concession Street, Suite 201,",
          addressLine2: "Kingston, Ontario",
          addressLine3: "K7K 6W6",
          telephone: "(613) 544-9771",
          fax: "(613) 544-3487",
          tollFree: "1-866-544-9771",
          email: "claims@pca-adj.com",
        },
        {
          name: "Belleville",
          image: "/images/office/belleville.png",
          headOffice: false,
          addressLine1: "122 Parks Drive, Suite 4.,",
          addressLine2: "Belleville Ontario.,",
          addressLine3: "K8N 4Z5",
          telephone: "(613) 962-9556",
          fax: "(613) 962-9596",
          tollFree: "1-888-962-9556",
          email: "claims@pca-adj.com",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.offices {
  display: flex;
  margin-top: 60px;
  margin-bottom: 100px;
  flex-wrap: wrap;
  .office {
    text-align: center;
    width: 33.33%;
    img {
      display: block;
      object-fit: cover;
      width: 80%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    p {
      margin: 0;
    }
    .head-office {
      font-size: 20px;
    }
    a {
      color: #000;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .offices {
    .office {
      width: 50%;
    }
  }
}

@media screen and (max-width: $mobile) {
  .offices {
    .office {
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="form-container">
    <div class="title-container">
      <h2>Claims Form</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean est leo,
        pulvinar venenatis ante vel, finibus malesuada eros. Vivamus nec commodo
        lorem.
      </p>
    </div>
    <form class="form" action="" method="post">
      <div class="first-section">
        <div class="form-group">
          <label for="fname">First Name</label>
          <input type="text" id="fname" name="firstname" />
        </div>
        <div class="form-group">
          <label for="lname">Last Name</label>
          <input type="text" id="lname" name="lastname" />
        </div>
      </div>
      <div class="second-section">
        <div class="form-group">
          <label for="address">Address</label>
          <input type="text" id="address" name="address" />
        </div>
        <div class="form-group">
          <label for="city">City</label>
          <input type="text" id="city" name="city" />
        </div>
        <div class="form-group state">
          <label for="state">Province/State</label>
          <input type="text" id="state" name="state" />
        </div>
        <div class="form-group zip">
          <label for="zipcode">Postal Code/ZIP Code</label>
          <input type="text" id="zipcode" name="zipcode" />
        </div>
      </div>
      <div class="third-section">
        <div class="form-group notes">
          <label for="notes">Notes</label>
          <textarea type="text" id="notes" name="notes" />
        </div>
      </div>
      <button>
        <img src="/images/icon/letter.svg" />
        <span>Submit Form</span>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: "ClaimsForm",
};
</script>

<style scoped lang="scss">
.form {
  margin: 60px 80px;
}
.first-section,
.second-section,
.third-section {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}
.form-group {
  margin-right: 40px;
  width: 25%;
  label {
    display: block;
    font-family: $secondaryFont;
    font-weight: 300;
    letter-spacing: 1.02px;
    font-size: 17px;
    line-height: 28px;
  }
  input {
    height: 40px;
    width: 100%;
    border: 1px solid #000;
  }
}

.zip {
  width: 15%;
}

.state {
  width: 15%;
}

.notes {
  width: 100%;
}
textarea {
  height: 200px;
  width: 75%;
  resize: none;
  border: 1px solid #000;
}
button {
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.1px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
  }
}

@media screen and (max-width: $mobile) {
  .form {
    margin: 40px;
  }
  .first-section,
  .second-section,
  .third-section {
    margin: 0;
  }
  .form-group {
    margin-top: 0;
    width: 100%;
    label {
      margin-top: 20px;
    }
    input {
      height: 35px;
      width: 100%;
    }
  }
  textarea {
    width: 100%;
    height: 120px;
  }
  button {
    margin-top: 20px;
  }
}
</style>

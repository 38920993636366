<template>
    <div v-if="loaded">
        <span data-aos="fade" data-aos-duration="10" style="display: none">
            no show
        </span>
        <HeaderBackground :title="job.Title" :office="job.Location" :imageURL="job.BackgroundImage ? apiURL+job.BackgroundImage.url : '/images/menu-background.png'" />
        <div class="job-page-container">
            <div class="jobs-container">
                <div class="job-navigation">
                    <div class="job-inner">
                        <h1>
                            Open Positions
                        </h1>
                        <div v-for="(job,i) in jobs" class="link-container" :key="i">
                            <div>
                                <router-link :to="`/job/${job.Slug}`">
                                    {{job.Title}}, {{job.Location}}
                                </router-link>
                            </div>
                            <hr v-if="i !== jobs.length - 1"> 
                        </div>
                    </div>
                </div>
                <div class="job-container">
                    <div class="job-content">
                        <Markdown :source="job.JobDescription" />
                    </div>
                    <a class="btn" href="mailto:HR@pca-adj.com">
                        Apply Now
                        <img class="arrow-right" src="/images/icon/arrow-right.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
import Markdown from 'vue3-markdown-it';
import HeaderBackground from "../components/HeaderBackground.vue";

  export default {
    name: "Jobs ",
    components: {
        Markdown,
        HeaderBackground
    },
    data() {
      return {
        apiURL: 'https://strapi.uptowncreative.io',
      };
    },
    computed: {
        jobs(){
            return this.$store.getters.jobs;
        },
        loaded(){
            return this.$store.getters.jobsLoaded;
        },
        job() {
            const jobID = this.$route.params.slug;
            const jobs = this.$store.getters.jobs;
            const job = jobs.find(item => item.Slug === jobID)
            return job;
        },
    },
  };
  </script>
  
<style scoped lang="scss">
.jobs-container{
    display: flex;
}

.job-navigation{
    min-width: 300px;
    width: 25%;
    border-right: 1px solid #000;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    h1{
        margin-bottom: 40px;
        margin-top: 30px;
    }
    a{
        display: block;
        color: #000;
        text-decoration: none;
        font-weight: 300;
        margin: 20px 0;
        font-family: $secondaryFont;
        &.active{
            font-weight: 400;
            color: #0267ff;
        }
    }
}

.job-inner{
    width: 80%;
    padding: 0 40px;
}

.job-container{
    flex: 1;
    padding: 60px;
    padding-top: 20px;
    .btn{
        margin-top: 30px;
    }
}

::v-deep h1{
    font-size: 30px;
    line-height: 30px;
    margin-top: 50px;
}

::v-deep li {
    font-family: $secondaryFont;
    font-weight: 300;
    font-size: 17px;
    line-height: 28px;
    list-style-type: none;
    position: relative;    /* It's needed for setting position to absolute in the next rule. */
}

::v-deep  li::before {
    content: '·';
    position: absolute;
    left: -0.8em; 
    font-size: 1.2em;     
}

hr{
    border: none;
    border-top: 1px solid #000;
}


@media screen and (max-width: $mobile) {
    .jobs-container{
        flex-direction: column-reverse;
    }
    .job-navigation{
        min-width: 100%;
        border: none;
        margin: 40px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }

    .job-inner{
        border-top: 1px solid #000;
        width: 80%;
        padding: 0;
    }
    hr{
        width: 90%;
    }
}

</style>
  
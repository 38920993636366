<template>
  <div class="service-container">
    <video-background
      videoURL="https://api.uptowncreative.io/uploads/_/assets/chadrogers/pca-services.mp4"
    >
      <h1>Experience the Difference Experience Makes</h1>
    </video-background>
    <div class="title-container">
      <h2>Our Services</h2>
      <p v-if="false">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tristique
        mattis nulla, in facilisis justo tincidunt eget. Sed vehicula turpis
        purus, eget sollicitudin.
      </p>
    </div>
    <div class="services">
      <div
        data-aos="fade-up"
        :data-aos-delay="getDelayInMS(i)"
        data-aos-offset="-60"
        class="service"
        v-for="(service, i) in services"
        :key="`Service ${service.title}`"
      >
        <img :src="service.img" :alt="service.title" />
        <h2>
          {{ service.title }}
        </h2>
        <p>
          {{ service.description }}
        </p>
      </div>
    </div>
    <footer-portal
      title="How Can We Help You?"
      subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      buttonText="Contact"
      buttonURL="#"
    />
  </div>
</template>

<script>
import VideoBackground from "../components/VideoBackground.vue";

export default {
  name: "Service",
  components: {
    VideoBackground,
  },
  data() {
    return {
      services: [
        {
          title: "Commercial Property",
          description:
            "With in-depth knowledge of commercial leases, contracts, and the workings of condominium corporations, our team of senior adjusters are available to assist you in all aspects of these types of claims.",
          img: "/images/services/11-commerc-liability.jpeg",
        },
        {
          title: "Long Haul Trucking",
          description:
            "Having been involved in the handling and claims management of over 30 fleets in our region, our team of adjusters are aware of the urgent need for prompt scene attendance and detailed investigations.",
          img: "/images/services/long-haul-trucking.jpeg",
        },
        {
          title: "Agriculture",
          description:
            "When a loss occurs at a farming operation, whether it involves a barn, livestock, heavy equipment or crops, the effect on the insured can be devastating. Our adjusters are well experienced in handling these types of losses.",
          img: "/images/services/03-agriculture.jpeg",
        },
        {
          title: "Environmental",
          description:
            "Be it a residential or commercial oil spill, or leakage of other contaminants, our senior adjusters are well versed and experienced in handling a variety of environmental losses.",
          img: "/images/services/04-environmental.jpeg",
        },
        {
          title: "Municipal Liability",
          description:
            "Appointed as control adjusters for numerous municipalities in the Eastern Ontario corridor, we have the background and experience to respond to and address municipal matters.",
          img: "/images/services/05-muni-liability.jpeg",
        },
        {
          title: "Pre & Post Blast Survey",
          description:
            "Our team of adjusters are fully experienced in completing these surveys, in order to ensure accurate documentation of neighbouring properties.",
          img: "/images/services/06-pre-post-blast.jpeg",
        },
        {
          title: "Accident Benefits",
          description:
            "Our experienced Accident Benefit adjusters are well aware of the strict timelines for these claims, and are pleased to assist you with these files, either on a task or full handle basis.",
          img: "/images/services/07-accident-benefits.jpeg",
        },
        {
          title: "Accident Benefits - Catastrophic Loss",
          description:
            "Our experienced Accident Benefit adjusters possess the skill and knowledge to compassionately handle these claims. We maintain an up to date understanding of the decisions of the Licence Appeal Tribunal and work with you to promptly and accurately adjudicate these claims via HCAI. ",
          img: "/images/services/08-cata-loss.jpeg",
        },
        {
          title: "Bodily Injury",
          description:
            "Our team of liability adjusters are cognizant of the importance of a prompt scene investigation, as well as witness contact, in order to obtain all of the information required to accurately  determine liability exposure. ",
          img: "/images/services/09-bodily-injury.jpeg",
        },
        {
          title: "Business Interruption",
          description:
            "A business interruption loss requires quick action and creative thinking, in order to minimize the potential exposure. Our team of adjusters would be pleased to help you with these losses.",
          img: "/images/services/10-business-int.jpeg",
        },
        {
          title: "Commercial Liability",
          description:
            "When incidents occur, promptly establishing contractual obligations via a thorough investigation is extremely important in maintaining a strong customer relationship between commercial clients. Our adjusters work with this goal in mind.",
          img: "/images/services/11-commerc-liability.jpeg",
        },
        {
          title: "Heavy Equipment / Machinery",
          description:
            "We are available to assist with these claims, whether it involves the loss of equipment or the breakdown of machinery.",
          img: "/images/services/12-heavy-equip.jpeg",
        },
        {
          title: "Marine Loss",
          description:
            "The use of jet-skis and power boats in our crowded waterways create hazards with sometimes tragic results. Detailed investigations are carried out by our adjusters, who have a thorough understanding and background in this area.",
          img: "/images/services/13-marine-loss.jpeg",
        },
        {
          title: "Personal Property",
          description:
            "When an insureds personal property is lost or damaged due to fire, flood or theft, it is always unsettling.  Our adjusters ensure that the appropriate coverage explanation is provided to the policyholder, with empathy and compassion.",
          img: "/images/services/14-personal-property.jpeg",
        },
        {
          title: "Product Liability",
          description:
            "Whether as a result of food poisoning, product failure or product malfunction, it is imperative that a prompt evidential investigation be carried out. We are available to assist you with these claims as the need arises.",
          img: "/images/services/15-product-liability.jpeg",
        },
        {
          title: "Professional Liability",
          description:
          "Be it doctors, nurses, lawyers, contractors or engineers, the prompt handling of an Errors & Omission claim is vital to the professional reputation of the individual. Our adjusters have many years of experience in this specialized field.",
          img: "/images/services/15-pro-liability.jpeg",
        },
        {
          title: "Transportation",
          description:
            "We have extensive experience investigating claims involving regional bus lines, school bus lines, transportation for disabled individuals and car rental companies.",
          img: "/images/services/16-public-transport.jpeg",
        },
      ],
    };
  },
  methods: {
    getDelayInMS(position) {
      if (window.innerWidth < 576) {
        return 400;
      }
      if (window.innerWidth < 1000){
        if (position % 2 === 1) {
          return 800;
        }
      }
      else {
        if (position % 3 === 1) {
          return 800;
        } else if (position % 3 === 2) {
          return 1200;
        }
      }
      return 400;
    },
  },
};
</script>

<style scoped lang="scss">
.title-container {
  margin: 0;
  background: #e5edf9;
  max-height: 373px;
}

.services {
  display: grid;
  gap: 50px 100px;
  grid-template-columns: repeat(3, 1fr);
  margin: 60px 40px;
}

.service {
  text-align: center;
  img {
    display: block;
    object-fit: auto;
    width: 80%;
    margin: 0 auto;
  }
  p {
    margin: 0 auto;
    width: 80%;
  }
}

@media screen and (max-width: 1000px) {
  .services {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: $mobile) {
  .title-container {
    p {
      margin: 0 40px;
    }
  }
  .services {
    grid-template-columns: auto;
  }
}
</style>

<template>
  <div class="form-container">
    <span data-aos="fade" data-aos-duration="10" style="display: none">
      no show
    </span>
    <div class="title-container" style="min-height: 60vh">
      <h2>This page is being prepared and will launch shortly.</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClaimsFormTemp",
};
</script>

<style scoped lang="scss">
.form {
  margin: 60px 80px;
}
.first-section,
.second-section,
.third-section {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}
.form-group {
  margin-right: 40px;
  width: 25%;
  label {
    display: block;
    font-family: $secondaryFont;
    font-weight: 300;
    letter-spacing: 1.02px;
    font-size: 17px;
    line-height: 28px;
  }
  input {
    height: 40px;
    width: 100%;
    border: 1px solid #000;
  }
}

.zip {
  width: 15%;
}

.state {
  width: 15%;
}

.notes {
  width: 100%;
}
textarea {
  height: 200px;
  width: 75%;
  resize: none;
  border: 1px solid #000;
}
button {
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.1px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
  }
}

@media screen and (max-width: $mobile) {
  .form {
    margin: 40px;
  }
  .first-section,
  .second-section,
  .third-section {
    margin: 0;
  }
  .form-group {
    margin-top: 0;
    width: 100%;
    label {
      margin-top: 20px;
    }
    input {
      height: 35px;
      width: 100%;
    }
  }
  textarea {
    width: 100%;
    height: 120px;
  }
  button {
    margin-top: 20px;
  }
}
</style>

<template>
  <div class="about-container">
    <span data-aos="fade" data-aos-duration="10" style="display: none">
      no show
    </span>
    <video-background imageURL="/images/about-bg.png">
      <h1>PCA Adjusters<br />Est. 1971</h1>
    </video-background>
    <img class="badge" src="/images/badge.png" alt="Badge" />
    <div class="title-container">
      <h2>Company Overview</h2>
      <p>
        We are PCA Adjusters - a boutique Independent Adjusting firm by design,
        located throughout the Eastern Ontario corridor. Founded and federally
        chartered in 1971, we have been serving our clients throughout this
        region for over 50 years.
        <br /><br />
        When claims happen, engaging experienced professionals are essential to
        understanding the associated events. The adjusters at PCA conduct
        thorough investigations facilitating efficient file resolution.
        <br /><br />
        Complimented with state of the art technology, we hope you will take a
        moment to view our on-line brochure. At PCA, we shall always put our
        best efforts in “Adjusting to Meet Your Needs.™”
        <br /><br />
        <b>"Price is what you pay. Value is what you get"</b>
        <br />
        Warren Buffet
      </p>
    </div>
    <hr />
    <div class="jobs">
      <div class="title-container">
        <h2>Jobs</h2>
        <p>
          If you are an individual possessing a high professional standard with
          insurance based background, we would like to hear from you.
          <b
            >Please email us at the applicable address and attach your resume in
            Microsoft Word, HTML or PDF format.</b
          >
        </p>
      </div>
      <div v-if="jobs.length > 0" class="job-posting">
        <div>
          <h2>Open Positions</h2>
        </div>
        <hr class="line" />
        <div v-for="(job, i) in jobs" class="job-postings" :key="i">
          <div class="job">
            <p>
              {{ job.Title }}, {{job.Location}}
            </p>
            <router-link class="btn" :to="`/job/${job.Slug}`">
              Details
              <img class="arrow-right" src="/images/icon/arrow-right.svg" />
            </router-link>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBackground from "../components/VideoBackground.vue";

export default {
  name: "About",
  components: {
    VideoBackground,
  },
  data() {
    return {
      jobFiles:[
        '/images/jobs/Job Description-Ottawa.pdf',
        '/images/jobs/Job Description-Kingston.pdf',
        '/images/jobs/Job Description-Belleville.pdf',
      ]
    };
  },
  computed:{
    jobs(){
      return this.$store.getters.jobs;
    },
  }
};
</script>

<style scoped lang="scss">
.badge {
  display: block;
  position: absolute;
  margin: 0 auto;
  margin-top: -75.5px;
  width: 151px;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%) !important;
}
.jobs {
  margin: 60px auto;
}
.line {
  margin: 0 auto;
  width: 60%;
}
.job-postings {
  width: 60%;
  margin: 0 auto;
}
.job-posting {
  text-align: center;
  margin-bottom: 80px;
  h2 {
    margin: 0 auto;
    padding: 20px 0;
  }
}
.job {
  margin: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .job-postings {
    width: 80%;
  }
  .line {
    width: 80%;
  }
  .job {
    margin: 20px;
  }
}

@media screen and (max-width: $mobile) {
  .job {
    margin: 20px 0;
  }
}
</style>

<template>
    <section class="header-background">
      <div class="header-background-container">
        <div class="header-background-background">
            <figure class="header-background-asset">
              <img :src="imageURL" />
            </figure>
        </div>
        <div class="header-background-gradient"></div>
        <div class="header-background-foreground">
          <div class="header-background-content">
            <h1 class="title">
                {{title}}
            </h1>
            <h1>
                {{office}} Office
            </h1>
            <div>
                <a class="btn" href="mailto:HR@pca-adj.com">
                    Apply Now
                    <img class="arrow-right" src="/images/icon/arrow-right.svg" />
                </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "HeaderBackground",
    props: {
        title: String,
        office: String,
        imageURL: String,
    },
  };
  </script>
  <style lang="scss" scoped>
.header-background{
    width: 100%;
    &-container{
        width: 100%;
        min-height: 400px;
        height: 50vh;
        margin-top: -136px;
        margin-bottom: 0;
        position: relative;
    }
    &-background{
        position: relative;
        height: 100%;
    }
    &-asset {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-gradient {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, #000, #000);
        opacity: 0.35;
    }
    &-foreground {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
    }
    &-content {
        margin-top: 136px;
        color: #fff;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        .title{
            margin: 0;
            font-size: 50px;
            font-weight: 400;
        }
        h1{
            font-weight: 400;
        }
        a{
            margin-top: 10px;
        }
    }
}
</style>
  
<template>
  <div class="employee">
    <a
      data-aos="fade"
      data-aos-duration="0"
      class="back-to-home"
      href="#"
      @click.prevent="$router.go(-1)"
    >
      <img src="/images/icon/back.svg" />
      Back to Our Team
    </a>
    <span data-aos="fade" data-aos-duration="10" style="display: none">
      no show
    </span>
    <div class="spinner" v-if="!employee">
      <easy-spinner size="80" color="#F3D4AE" />
    </div>
    <div class="employee-container" v-else>
      <div class="employee-info">
        <img :src="url + employee.ProfileImage.url" :alt="employee.Name" />
        <h2>
          {{ employee.Name }}
        </h2>
        <p>
          <b>{{ employee.Title }}</b>
        </p>
        <p v-if="employee.FSRA">FSRA LIC.NO.: {{ employee.FSRA }}</p>
        <br />
        <p v-if="employee.LocalNumber">
          <b>Local: </b> {{ employee.LocalNumber }}
        </p>
        <p v-if="employee.DirectNumber">
          <b>Direct: </b> {{ employee.DirectNumber }}
        </p>
        <a v-if="employee.Email" :href="`mailto:${employee.Email}`">{{
          employee.Email
        }}</a>
        <a
          class="vcard"
          v-if="employee.vCard"
          :href="url + employee.vCard.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img class="card" src="/images/icon/vcard.svg" />
          vCard
        </a>
      </div>
      <div class="employee-bio">
        <Markdown v-if="employee.Biography" :source="employee.Biography" />
      </div>
    </div>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import AOS from "aos";

export default {
  name: "TeamMember",
  components: {
    Markdown,
  },
  data() {
    return {
      url: "https://strapi.uptowncreative.io",
    };
  },
  computed: {
    employee() {
      return this.$store.getters.employees.find(
        (employee) => employee.Slug === this.$route.params.slug
      );
    },
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style scoped lang="scss">
.back-to-home {
  display: block;
  color: #000000;
  margin: 40px 60px;
  font-family: $secondaryFont;
  font-weight: 300;
  img {
    height: 16px;
  }
}
.employee-container {
  display: flex;
  flex-wrap: wrap;
  margin: 60px 0;
}
.employee-info {
  width: 40%;
  padding: 0 5%;
  img {
    width: 80%;
    max-width: 275px;
    border: 4px solid #0267ff;
  }
  h2 {
    margin: 32px 0;
  }
  p {
    margin: 0;
  }
  a {
    font-family: $secondaryFont;
    display: block;
    color: #0267ff;
    &:hover {
      color: #0267ff;
    }
  }
  .vcard {
    margin-top: 10px;
  }
  .card {
    max-width: unset !important;
    width: 20px !important;
    border: none;
    margin-right: 5px;
    vertical-align: middle;
  }
}
.employee-bio {
  flex: 1;
  border-left: 1px solid #000;
  padding: 0 5%;
  ::v-deep ul li::marker {
    font-size: 8px;
  }
  ::v-deep ul li{
    font-family: $secondaryFont;
    font-weight: 300;
    font-size: 16px;
  }
}

@media screen and (max-width: $mobile) {
  .employee-info {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 20px 40px;
  }
  .employee-bio {
    border-left: none;
    text-align: left;
    margin: 20px 40px;
    h2 {
      text-align: center;
    }
  }
}
</style>
